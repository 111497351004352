import { Grid, Typography } from '@mui/material';
import MFSelectField from '../../lib/formik/SelectField';
import { ProceedSaveLater, SubHeading } from '../investors/components';
import { MFTextField } from '../../lib/formik';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { Applicant, Broker, BrokerList } from '../../redux-store/types/api-types';
import {
  DLCLMasters,
  grossAnnualMasters,
  investorTypeMasters,
  PEPsMasters,
} from '../../utils/constant';
import { DatePicker } from '../../lib/formik/DatePicker';
import React, { useEffect, useState } from 'react';
import {
  applicationComparison,
  clearclIdField,
  cleardpIdField,
  currencyConversion,
  dlclIdFieldMandatory,
  removeSingleQuote,
  saveForLater,
} from '../../utils/utilityFunctions';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { getBrokers, updateApplication } from '../../redux-store/actions/application';
import { NonIndividualContributorValidationSchema } from '../../utils/schema';
import { useSnackbar } from 'notistack';
import { DematDetails } from '../investors/additionalKYCDetails';
import { SearchableSelect } from '../../lib/formik/searchSelectField';

const contributorObject: Partial<Applicant> = {
  name: '',
  cityOfIncorporation: '',
  ckycNo: '',
  investorType: '',
  dateOfBirth: '',
  panNo: '',
  grossAnnualIncome: '',
  netWorth: '',
  politicallyExposedPersonStatus: '',
  dlclId: dlclIdFieldMandatory() ? '' : 'none',
  dpId: '',
  clId: '',
  nameOfBroker: '',
};

export type Values = {
  saveType: string;
  modeOfHolding: string;
  applicants: Partial<Applicant>[];
};

const initialValues: Values = {
  modeOfHolding: 'single',
  applicants: [contributorObject],
  saveType: 'save and proceed',
};

export default function ContributorDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const [contributorValues, setContributorValues] = useState(initialValues);
  const history = useHistory();
  const dispatch = useDispatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [brokerList, setBrokerList] = useState<BrokerList>();

  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(getBrokers())) as unknown as BrokerList;
        setBrokerList(response as BrokerList);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  useEffect(() => {
    try {
      const { applicants = [] } = application || {};
      setContributorValues({
        ...contributorValues,
        applicants: applicants.length
          ? applicants.map((applicant) => {
              return {
                ...applicant,
                dlclId: applicant.dlclId ? applicant.dlclId : dlclIdFieldMandatory() ? '' : 'none',
                nameOfBroker: applicant.nameOfBroker || '',
              };
            })
          : [contributorObject],
      });
    } catch (e) {
      console.error((e as Error).message);
    }
  }, [application]);

  const handleApplicant = async (values: Values) => {
    const { saveType, applicants = [] } = values;
    const { id, currentStep, banks = [] } = application || {};
    // const isAllBanksPennyChecked = banks.length
    //   ? banks.every((bank) => bank.pennydropCheck)
    //   : false;
    // const contributorDetailsFieldsCheck = application?.applicants
    //   .map((applicant, index) => {
    //     return applicants.map((_applicant, ind) => {
    //       if (index === ind) {
    //         const { investorType } = _applicant;
    //         if (investorType !== applicant.investorType) {
    //           return 'true';
    //         }
    //         return 'false';
    //       }
    //       return 'false';
    //     });
    //   })
    //   .flat();
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants
          .map((applicant) => ({
            ...applicant,
            name: removeSingleQuote(applicant.name),
            cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
          }))
          ?.sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        banks:
          values.applicants[0].name?.split("'").join('').trim() ===
          application?.applicants[0].name?.split("'").join('').trim()
            ? banks
            : banks.map((bank) => ({
                ...bank,
                pennydropCheck: false,
                pennydropVerified: false,
              })),
        applicants: values.applicants
          .map((applicant) => ({
            ...applicant,
            name: removeSingleQuote(applicant.name),
            cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
            clId: clearclIdField(applicant.dlclId || '') ? '' : applicant.clId,
            dpId: cleardpIdField(applicant.dlclId || '') ? '' : applicant?.dpId?.toUpperCase(),
          }))
          .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
        modeOfHolding: 'single',
        currentStep: !!currentStep && currentStep > 1 ? currentStep : Number(currentStep) + 1,
      }
    );

    if (id && !checkApplication) {
      await dispatch(
        updateApplication({
          body: {
            ...application,
            banks:
              values.applicants[0].name?.split("'").join('').trim() ===
              application?.applicants[0].name?.split("'").join('').trim()
                ? banks
                : banks.map((bank) => ({
                    ...bank,
                    pennydropCheck: false,
                    pennydropVerified: false,
                  })),
            applicants: values.applicants.map((applicant) => ({
              ...applicant,
              name: removeSingleQuote(applicant.name),
              cityOfIncorporation: removeSingleQuote(applicant.cityOfIncorporation),
              clId: clearclIdField(applicant.dlclId || '') ? '' : applicant.clId,
              dpId: cleardpIdField(applicant.dlclId || '') ? '' : applicant?.dpId?.toUpperCase(),
              nameOfBroker: cleardpIdField(applicant.dlclId || '') ? '' : applicant.nameOfBroker,
            })),
            modeOfHolding: 'single',
            currentStep: 2,
            // !!currentStep && currentStep > 1
            //   ? (values.applicants[0].name?.trim() !== application?.applicants[0].name?.trim() &&
            //       isAllBanksPennyChecked) ||
            //     (contributorDetailsFieldsCheck as unknown as string[])?.includes('true')
            //     ? 2
            //     : currentStep
            //   : (values.applicants[0].name?.trim() !== application?.applicants[0].name?.trim() &&
            //       isAllBanksPennyChecked) ||
            //     (contributorDetailsFieldsCheck as unknown as string[])?.includes('true')
            //   ? 2
            //   : Number(currentStep) + 1,
          },
          applicationId: id,
          ...(saveType !== 'save and proceed' && {
            toastMessage: '',
          }),
        })
      );
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      setLoading(true);
      const { id, applicant1ReferenceId = '', applicationNumber } = application || {};
      const { saveType } = values;
      await handleApplicant(values);
      saveType === 'save and proceed'
        ? history.push('contact-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
      if (saveType !== 'save and proceed') {
        enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      //history.push(`document-details`, { applicationType: 'non_individual' });
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={contributorValues}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            NonIndividualContributorValidationSchema(
              brokerList?.broker_list as Broker[],
              application?.createdAt || ''
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <>
          <Grid
            container
            rowSpacing={1}
            //columnSpacing={5}
            sx={{
              width: '100%',
              ml: 0,
              '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            {values.applicants.map((applicant, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.name`}
                    label="Name of Entity *"
                    placeholder="Enter Name of Entity"
                    disabled={applicant.dataFetchedFromKRA || false}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label={'Date of Registration/Incorporation *'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    placeholder={'DD/MM/YYYY'}
                    name={`applicants.${index}.dateOfBirth`}
                    disabled={applicant.dataFetchedFromKRA || false}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.cityOfIncorporation`}
                    label="Place of Registration/Incorporation *"
                    placeholder="Enter Place of Registration/Incorporation"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.ckycNo`}
                    label="CKYC No./CKYC Acknowledge No./KIN No "
                    placeholder="Enter CKYC No./CKYC Acknowledge No./KIN No"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.investorType`}
                    label="Investor Type *"
                    items={Object.keys(investorTypeMasters).map((investor) => ({
                      key: investorTypeMasters[investor],
                      value: investor,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.panNo`}
                    label="PAN *"
                    placeholder="Enter PAN"
                    disabled={true}
                  />
                </Grid>
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  Other Details
                </SubHeading>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.grossAnnualIncome`}
                    label="Gross Annual Income *"
                    items={grossAnnualMasters.map((grossIncome) => ({
                      key: grossIncome,
                      value: grossIncome,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`applicants.${index}.netWorth`}
                    label="Net Worth *"
                    placeholder="Enter Net Worth"
                  />
                  {values.applicants[index].netWorth &&
                    !isNaN(Number(values.applicants[index].netWorth)) && (
                      <Typography sx={{ fontSize: 13 }}>
                        {currencyConversion(values.applicants[index].netWorth)}
                      </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MFSelectField
                    name={`applicants.${index}.politicallyExposedPersonStatus`}
                    label="Politically Exposed Person (PEP) Status"
                    items={PEPsMasters.map((pep) => ({ key: pep, value: pep }))}
                    disabled={true}
                  />
                </Grid>
                <DematDetails
                  values={values}
                  index={index}
                  brokers={brokerList?.broker_list as Broker[]}
                  disabled={false}
                />
              </React.Fragment>
            ))}
            <ProceedSaveLater
              saveLater={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                  })),
                  saveType: 'save for later',
                });
              }}
              saveAndProceed={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                  })),
                  saveType: 'save and proceed',
                });
              }}
              loader={loading}
              clickedButton={values.saveType}
            />
          </Grid>
        </>
      )}
    </Formik>
  );
}
