import { Grid } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import { Applicant, Broker, BrokerList } from '../../redux-store/types/api-types';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  grossAnnualMasters,
  occupationDetailsMasters,
  PEPsMasters,
  USER_ROLES,
  DLCLMasters,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  getApplicantName,
  saveForLater,
  clearclIdField,
  cleardpIdField,
  dlclIdFieldMandatory,
  getclIdField,
  getdpIdField,
  isCDSL,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useEffect, useState } from 'react';
import { getBrokers, updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { KYCDetailsSchema } from '../../utils/schema';
import { MFTextField } from '../../lib/formik';
import { useSnackbar } from 'notistack';
import { SearchableSelect } from '../../lib/formik/searchSelectField';

type Values = {
  applicants: Partial<Applicant>[];
  saveType: string;
};

const initialValues: Values = {
  applicants: [
    {
      occupationDetails: '',
      grossAnnualIncome: '',
      politicallyExposedPersonStatus: 'Not Applicable',
      ckycNo: '',
      dlclId: dlclIdFieldMandatory() ? '' : 'none',
      dpId: '',
      clId: '',
      nameOfBroker: '',
    },
  ],
  saveType: 'save and proceed',
};

const KYCDetails = ({ applicant, index }: { applicant: string; index: number }): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <>
      <SubHeading>Additional KYC Details of {applicant} Applicant</SubHeading>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.occupationDetails`}
          label="Occupation Details *"
          items={occupationDetailsMasters.map((occupation) => ({
            key: occupation,
            value: occupation,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.grossAnnualIncome`}
          label="Gross Annual Income"
          items={grossAnnualMasters.map((grossIncome) => ({
            key: grossIncome,
            value: grossIncome,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.politicallyExposedPersonStatus`}
          label="Politically Exposed Person (PEP) Status *"
          items={PEPsMasters.map((pep) => ({ key: pep, value: pep }))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.ckycNo`}
          label="CKYC Number"
          placeholder={'Enter CKYC number'}
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
          disabled={[USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)}
        />
      </Grid>
    </>
  );
};

export const DematDetails = ({
  values,
  index,
  brokers,
  disabled,
}: {
  values: Values;
  index: number;
  brokers: Broker[];
  disabled: boolean;
}): JSX.Element => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.dlclId`}
          label={`Demat Account Details (for allotment of units) ${
            dlclIdFieldMandatory() ? '*' : ''
          }`}
          items={Object.keys(DLCLMasters).map((Id) => ({
            key: DLCLMasters[Id],
            value: Id,
          }))}
          disabled={disabled}
        />
      </Grid>
      {getdpIdField(values.applicants[index].dlclId || '') && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.dpId`}
            label={`${isCDSL(values.applicants[index].dlclId || '') ? 'DPCL ID' : 'DP ID'} *`}
            placeholder={`Enter ${
              isCDSL(values.applicants[index].dlclId || '') ? 'DPCL ID' : 'DP ID'
            }`}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            disabled={disabled}
          />
        </Grid>
      )}
      {getclIdField(values.applicants[index].dlclId || '') && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.clId`}
            label="CL ID *"
            placeholder="Enter CL ID"
            disabled={disabled}
          />
        </Grid>
      )}
      {getdpIdField(values.applicants[index].dlclId || '') && (
        <Grid item xs={12} sm={6}>
          <SearchableSelect
            name={`applicants.${index}.nameOfBroker`}
            label="Name of Broker/Depository Participant (DP)"
            items={brokers?.map((broker: Broker) => ({
              key: broker.value,
              value: broker.key,
            }))}
            searchFieldPlaceholder={'Search Broker/Depository Participant'}
          />
        </Grid>
      )}
    </>
  );
};

export default function AdditionalKYCDetails(): JSX.Element {
  const [kycDetails, setkycDetails] = useState(initialValues);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [brokerList, setBrokerList] = useState<BrokerList>();

  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(getBrokers())) as unknown as BrokerList;
        setBrokerList(response as BrokerList);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  useEffect(() => {
    const { applicants: exisitingApplicants = [] } = application || {};
    setkycDetails({
      ...kycDetails,
      applicants: exisitingApplicants.map((applicant) => ({
        occupationDetails: applicant.occupationDetails || '',
        grossAnnualIncome: applicant.grossAnnualIncome || '',
        politicallyExposedPersonStatus: applicant.politicallyExposedPersonStatus
          ? applicant.politicallyExposedPersonStatus
          : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
          ? ''
          : 'Not Applicable',
        ckycNo: applicant.ckycNo || '',
        dlclId: applicant.dlclId ? applicant.dlclId : dlclIdFieldMandatory() ? '' : 'none',
        dpId: applicant.dpId || '',
        clId: applicant.clId || '',
        nameOfBroker: applicant.nameOfBroker || '',
      })),
    });
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const { applicants, saveType } = values;
      const {
        applicants: exisitingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const updatedApplicants = exisitingApplicants.map((applicant, index) => ({
        ...applicant,
        ...applicants[index],
        clId: clearclIdField(applicants[index].dlclId || '') ? '' : applicants[index].clId,
        dpId: cleardpIdField(applicants[index].dlclId || '')
          ? ''
          : applicants[index]?.dpId?.toUpperCase(),
        nameOfBroker: cleardpIdField(applicants[index].dlclId || '')
          ? ''
          : applicants[index].nameOfBroker,
      }));
      const checkApplication = applicationComparison(application, {
        ...application,
        applicants: updatedApplicants,
        currentStep: !!currentStep && currentStep > 3 ? currentStep : Number(currentStep) + 1,
      });
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedApplicants,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status === 'sent_to_amc_approver' &&
                    hasPOA &&
                    USER_ROLES.SUBDISTRIBUTOR === role
                  ? 'draft'
                  : status,
              currentStep: 4,
              //!!currentStep && currentStep > 3 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('fatca', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('fatca', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };
  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <Formik
      initialValues={kycDetails}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(
            values,
            KYCDetailsSchema(brokerList?.broker_list as Broker[], application?.createdAt || ''),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {values.applicants.map((_, index) => (
            <>
              <KYCDetails applicant={getApplicantName(index + 1)} key={index} index={index} />

              <DematDetails
                values={values}
                index={index}
                brokers={brokerList?.broker_list as Broker[]}
                disabled={isFieldDisabled || false}
              />
            </>
          ))}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
