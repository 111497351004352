/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { validateYupSchema } from 'formik';
import { bankObject } from '../../components/investors/bankDetails';
//import { getDocumentDetails } from '../../components/investors/documentDetails';
import { contactPersonObject } from '../../components/NonIndividualInvestor/ContactDetails';
import { document_object } from '../../components/NonIndividualInvestor/DocumentDetails';
import { percentageError } from '../../components/NonIndividualInvestor/ubo';
import {
  applicantStatusMasters,
  APPLICATION_TYPE_FOR_DOCUMENTS,
  bankOverseasDomesticMaster,
  investorTypeMasters,
  IS_PENNYDROP_APPLICABLE_FOR_AMC,
  occupationDetailsMasters,
  salutationsMasters,
  USER_ROLES,
} from '../../utils/constant';
import { emailRegex, individualPanRegex } from '../../utils/regex';
import {
  bankDetailsSchema,
  contributorDetailsSchema,
  documentDetailsSchema,
  FATCAValidationSchema,
  KYCDetailsSchema,
  nomineeDetailsSchema,
  nonIndividualContactDetailsSchema,
  NonIndividualContributorValidationSchema,
  nonIndividualDocumentDetailsSchema,
  overseasBankDetailsSchema,
} from '../../utils/schema';
import {
  checkAllNri,
  checkBankTypeExistOrNot,
  checkNormalNri,
  checkNriFRI,
  cleardpIdField,
  getAddressData,
  getBankAddress,
  getNomineeRelation,
  getRelation,
  isMinor,
  dlclIdFieldMandatory,
  isForeignEntityTypeInvestor,
} from '../../utils/utilityFunctions';
import { AuthorisedErrors, BankError, CALL_API, UboErrors } from '../middleware';

import {
  Applicant,
  ApplicationProps,
  Bank,
  NomineeType,
  Document,
  uboTypes,
  FetchUBORequestBody,
  StampPapersType,
  dashboardOnboardingType,
  monthwiseOnboardingSummaryType,
  distributorWisecommitmentType,
  monthwiseCommitmentAmountType,
  ubo,
  FatcaMdms,
  individuals_Poa_nonIndividuals_Documents,
  Groups,
  GroupSignatories,
  BrokerList,
  Broker,
} from '../types/api-types';
import {
  CreateApplicationRequestBody,
  CREATE_APPLICATION,
  CREATE_APPLICATION_SUCCESS,
  GET_APPLICATION,
  GET_APPLICATION_SUCCESS,
  GET_ALL_APPLICATION_SUCCESS,
  GET_STAMPPAPER_DETAILS_SUCCESS,
  GET_STAMPPAPER_DETAILS,
  GET_DISTRIBUTOR_COMMITMENTAMOUNT_SUCCESS,
  GET_DISTRIBUTOR_COMMITMENTAMOUNT,
  GET_MONTHWISE_COMMITMENTAMOUNT_SUCCESS,
  GET_MONTHWISE_COMMITMENTAMOUNT,
  GET_ONBOARDING_SUMMARY_SUCCESS,
  GET_ONBOARDING_SUMMARY,
  GET_MONTHWISE_ONBOARDING_SUMMARY_SUCCESS,
  GET_MONTHWISE_ONBOARDING_SUMMARY,
  GET_ALL_APPLICATION,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION,
  AddJointHolderRequestBody,
  ADD_APPLICANT_SUCCESS,
  ADD_APPLICANT,
  GetAllApplicantsRequestBody,
  GetAllApplicantionsResponseBody,
  PENNY_DROP_VERIFICATION_SUCCESS,
  PENNY_DROP_VERIFICATION,
  GET_Documents_SUCCESS,
  GET_Documents,
  GET_Ubo_SUCCESS,
  GET_Ubo,
  UBO_LISTING_SUCCESS,
  UBO_LISTING,
  GET_Fatca_SUCCESS,
  GET_Fatca,
  GET_BROKERS_SUCCESS,
  GET_BROKERS,
} from '../types/application';
import { mdmsCountriesList, nationaliyType } from '../types/mdms';

export const createApplication =
  (body: CreateApplicationRequestBody) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications`,
        method: 'POST',
        types: [CREATE_APPLICATION_SUCCESS, CREATE_APPLICATION],
        body,
      },
    });
  };

export const getApplicationDetails =
  (applicationId: string, method = '') =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/${applicationId}`,
        method: method ? 'DELETE' : 'GET',
        types: [GET_APPLICATION_SUCCESS, GET_APPLICATION],
      },
    });
  };
export const getParams = (paramsObj: Partial<GetAllApplicantsRequestBody>) => ({
  type: 'GET_PARAMS_DATA',
  paramsObj,
});
export const getAllApplications =
  (params: Partial<GetAllApplicantsRequestBody>) =>
  async (dispatch: any): Promise<GetAllApplicantionsResponseBody> => {
    await dispatch(getParams(params));
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications`,
        method: 'GET',
        types: [GET_ALL_APPLICATION_SUCCESS, GET_ALL_APPLICATION],
        params: {
          limit: 10,
          sort: 'createdAt',
          order: 'DESC',
          ...params,
        },
      },
    });
  };

export const onboardingSummary =
  () =>
  async (dispatch: any): Promise<dashboardOnboardingType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/onboardingSummary`,
        method: 'GET',
        types: [GET_ONBOARDING_SUMMARY_SUCCESS, GET_ONBOARDING_SUMMARY],
      },
    });
  };

export const monthwiseOnboardingSummary =
  () =>
  async (dispatch: any): Promise<monthwiseOnboardingSummaryType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/monthwiseOnboardingSummary?months=4`,
        method: 'GET',
        types: [GET_MONTHWISE_ONBOARDING_SUMMARY_SUCCESS, GET_MONTHWISE_ONBOARDING_SUMMARY],
      },
    });
  };

export const distributorWisecommitmentAmount =
  () =>
  async (dispatch: any): Promise<distributorWisecommitmentType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/distributorWisecommitmentAmount`,
        method: 'GET',
        types: [GET_DISTRIBUTOR_COMMITMENTAMOUNT_SUCCESS, GET_DISTRIBUTOR_COMMITMENTAMOUNT],
      },
    });
  };

export const monthwiseCommitmentAmount =
  () =>
  async (dispatch: any): Promise<monthwiseCommitmentAmountType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/dashboard/monthwiseCommitmentAmount`,
        method: 'GET',
        types: [GET_MONTHWISE_COMMITMENTAMOUNT_SUCCESS, GET_MONTHWISE_COMMITMENTAMOUNT],
      },
    });
  };

// export const getStampPaperCount =
//   (params: Partial<GetAllApplicantsRequestBody>) =>
//   async (dispatch: any): Promise<GetAllApplicantionsResponseBody> => {
//     return await dispatch({
//       [CALL_API]: {
//         url: `/leegality/stampPaperDetails`,
//         method: 'GET',
//         types: [GET_STAMPPAPER_DETAILS_SUCCESS, GET_STAMPPAPER_DETAILS],

//       },
//     });
//   };

export const getStampPaperCount =
  () =>
  async (dispatch: any): Promise<StampPapersType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/leegality/stampPaperDetails`,
        method: 'GET',
        types: [GET_STAMPPAPER_DETAILS_SUCCESS, GET_STAMPPAPER_DETAILS],
      },
    });
  };

export const updateApplication =
  ({
    body,
    applicationId,
    toastMessage,
  }: {
    body: Partial<ApplicationProps>;
    applicationId: string;
    toastMessage?: string;
  }) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    const showToast = typeof toastMessage !== 'undefined';
    if (showToast) {
      toastMessage =
        `Application ${body.applicationNumber || ''} - ` + (toastMessage || ' Saved successfully');
    }
    try {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/applications/${applicationId}/update`,
          method: 'POST',
          types: [UPDATE_APPLICATION_SUCCESS, UPDATE_APPLICATION],
          body,
          showToast,
          toastMessage,
        },
      });
    } catch (error: any) {
      console.log('catch error on update application', error);
      throw error;
    }
  };

export const addJointHolder =
  (body: AddJointHolderRequestBody, applicationId: string) =>
  async (dispatch: any): Promise<ApplicationProps> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/${applicationId}/addApplicant`,
        method: 'POST',
        types: [ADD_APPLICANT_SUCCESS, ADD_APPLICANT],
        body,
      },
    });
  };

export const validateContributorDetails = async (
  applicants: Partial<Applicant>[],
  role: any,
  nationalitiesMdmsMasters: nationaliyType
) => {
  const schemaData = {
    applicants: applicants.map((applicant) => ({
      ...applicant,
      nationality: applicant.nationality
        ? applicant.nationality.toUpperCase()
        : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'INDIAN',
      status: checkAllNri(applicant.status as string, applicant.statusSubType as string)
        ? applicant.statusSubType || ''
        : applicant.status,
      namePrefix:
        applicant.namePrefix && salutationsMasters.includes(applicant.namePrefix || '')
          ? applicant.namePrefix
          : 'Mr.',
      jointApplicantRelation: getRelation(applicant.relationShipWithFirstApplicant)
        ? applicant.relationShipWithFirstApplicant
        : 'Others',
      relationShipWithFirstApplicant: getRelation(applicant.relationShipWithFirstApplicant)
        ? ''
        : applicant.relationShipWithFirstApplicant,
    })),
    nationalityDropdown: nationalitiesMdmsMasters.countries.map((list) => list.nationality),
  };
  try {
    await validateYupSchema(schemaData, contributorDetailsSchema, true, schemaData);
  } catch (error) {
    throw `Contributor details`;
  }
};

export const validateKYCDetails = async (
  applicants: Partial<Applicant>[],
  role: any,
  brokerList: Broker[],
  createdDate: string
) => {
  const schemaData = {
    applicants: applicants.map((applicant) => ({
      occupationDetails: applicant.occupationDetails || '',
      grossAnnualIncome: applicant.grossAnnualIncome || '',
      politicallyExposedPersonStatus: applicant.politicallyExposedPersonStatus
        ? applicant.politicallyExposedPersonStatus
        : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'Not Applicable',
      ckycNo: applicant.ckycNo || '',
      dlclId: applicant.dlclId ? applicant.dlclId : dlclIdFieldMandatory() ? '' : 'none',
      dpId: applicant.dpId || '',
      clId: applicant.clId || '',
      nameOfBroker: applicant.nameOfBroker || '',
    })),
  };
  try {
    await validateYupSchema(
      schemaData,
      KYCDetailsSchema(brokerList as Broker[], createdDate || ''),
      true,
      schemaData
    );
  } catch (error) {
    throw `KYC details`;
  }
};

export const validateFATCA = async (
  applicants: Partial<Applicant>[],
  role: any,
  nationalitiesMdmsMasters: nationaliyType
) => {
  const schemaData = {
    applicants: applicants?.map((applicant) => ({
      typeOfAddressProvidedAtKRA: applicant.typeOfAddressProvidedAtKRA || '',
      taxResidentOfAnyCountryOtherThanIndia:
        applicant.taxResidentOfAnyCountryOtherThanIndia || false,
      placeOfBirth: applicant.placeOfBirth || '',
      countryOfBirth: applicant.countryOfBirth
        ? applicant.countryOfBirth.toUpperCase()
        : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'INDIA',
      countryOfNationality: applicant.countryOfNationality
        ? applicant.countryOfNationality.toUpperCase()
        : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
        ? ''
        : 'INDIA',
      taxCountryName: applicant.taxCountryName || '',
      taxID: applicant.taxID || '',
      idType: applicant.idType || '',
      nameOfEntity: applicant.nameOfEntity || '',
      dateOfIncorporation: applicant.dateOfIncorporation || '',
      cityOfIncorporation: applicant.cityOfIncorporation || '',
      countryOfIncorporation: applicant.countryOfIncorporation || '',
      entityExcemptionCode: applicant.entityExcemptionCode || '',
    })),
    countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
  };
  try {
    await validateYupSchema(schemaData, FATCAValidationSchema, true, schemaData);
  } catch (error) {
    throw `FATCA`;
  }
};

export const validateNomineeDetails = async (
  Nominees: Partial<NomineeType>[],
  doNotWishToNominate: boolean,
  applicants: Partial<Applicant>[]
) => {
  const schemaData = {
    doNotWishToNominate: doNotWishToNominate === null ? true : doNotWishToNominate,
    nominees: Nominees.length
      ? Nominees.map((nominee) => ({
          ...nominee,
          Relationship: getNomineeRelation(nominee.nomineeRelationship?.toUpperCase())
            ? nominee.nomineeRelationship
              ? nominee.nomineeRelationship.toUpperCase()
              : nominee.nomineeRelationship
            : 'OTHERS',
          nomineeRelationship: getNomineeRelation(nominee.nomineeRelationship?.toUpperCase())
            ? ''
            : nominee.nomineeRelationship,
        }))
      : [],
  };
  try {
    await validateYupSchema(schemaData, nomineeDetailsSchema(applicants), true, schemaData);
  } catch (error) {
    throw `Nominee details`;
  }
};

export const validateBankDetails = async (
  bankDetails: Partial<Bank>[],
  verifyPennydrop: boolean,
  applicationType: string,
  applicants: Partial<Applicant>[],
  investor_bank_type: string
) => {
  const setBank = bankDetails.map((bank) => ({
    ...bank,
    defaultBankAccount: !!bank.defaultBankAccount,
    bankAddress: getBankAddress(bank.address1, bank.address2, bank.address3),
  }));

  const schemaData = {
    applicationType: applicationType,
    status: applicants.length ? applicants[0].status : '',
    investor_bank_type:
      applicantStatusMasters[applicants[0].statusSubType as string] ===
        applicantStatusMasters.NRI_FRI ||
      investorTypeMasters[applicants[0]?.investorType as string] ===
        investorTypeMasters.foreign_entity
        ? investor_bank_type
        : 'domestic',
    banks: {
      domestic:
        bankDetails.length &&
        checkBankTypeExistOrNot(bankDetails.map((_bank) => _bank.bank_type) as string[], 'domestic')
          ? setBank.filter(
              (bank) =>
                bankOverseasDomesticMaster[bank.bank_type || ''] ===
                bankOverseasDomesticMaster.domestic
            )
          : [{ ...bankObject('domestic') }],
      overseas:
        bankDetails.length &&
        checkBankTypeExistOrNot(bankDetails.map((_bank) => _bank.bank_type) as string[], 'overseas')
          ? setBank.filter(
              (bank) =>
                bankOverseasDomesticMaster[bank.bank_type || ''] ===
                bankOverseasDomesticMaster.overseas
            )
          : applicantStatusMasters[applicants[0].statusSubType as string] ===
              applicantStatusMasters.NRI_FRI ||
            investorTypeMasters[applicants[0]?.investorType as string] ===
              investorTypeMasters.foreign_entity
          ? [{ ...bankObject('overseas') }]
          : [],
    },
  };
  try {
    if (
      IS_PENNYDROP_APPLICABLE_FOR_AMC &&
      verifyPennydrop &&
      bankOverseasDomesticMaster[investor_bank_type] === bankOverseasDomesticMaster.domestic
    ) {
      const isAllBanksPennyChecked = bankDetails.every((bank) => bank.pennydropCheck);
      if (!isAllBanksPennyChecked) {
        throw new BankError('Please make sure that all the banks are verified');
      }
    }
    await validateYupSchema(
      schemaData,
      bankOverseasDomesticMaster[investor_bank_type || ''] === bankOverseasDomesticMaster.domestic
        ? bankDetailsSchema(applicants)
        : overseasBankDetailsSchema,
      true,
      schemaData
    );
  } catch (error) {
    if (error instanceof BankError) {
      throw error;
    }
    throw `Bank details`;
  }
};

export const validateDocuments = async (
  applicants: Partial<Applicant>[],
  hasPOA: boolean,
  documentsData: Document,
  Nominees: Partial<NomineeType>[],
  role: string
) => {
  const schemaData = {
    applicants: applicants.map((applicant, applicant_index) => {
      let docData = '';
      if (
        !hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.Individual
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.INDIVIDUAL;
      }
      if (
        hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.Individual
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.INDIVIDUAL_POA;
      }
      if (!hasPOA && checkNormalNri(applicant.status as string, applicant.statusSubType)) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI;
      }
      if (hasPOA && checkNormalNri(applicant.status as string, applicant.statusSubType)) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI_POA;
      }
      if (!hasPOA && checkNriFRI(applicant.status as string, applicant.statusSubType as string)) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI_FRI;
      }
      if (hasPOA && checkNriFRI(applicant.status as string, applicant.statusSubType as string)) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI_POA_FRI;
      }
      return {
        documents:
          typeof documentsData !== 'undefined'
            ? ((documentsData as Document)[docData] || [])
                .filter((doc) => {
                  if (
                    hasPOA &&
                    applicant.applicant_type !== '1' &&
                    doc.documentType === 'poaNotarized'
                  ) {
                    return;
                  }
                  return doc;
                })
                .filter((ele) => ele)
                .map((_doc) => {
                  if (role === USER_ROLES.POAAPPROVER && _doc.documentType === 'poaNotarized') {
                    return { ..._doc, required: 'true' };
                  }
                  return _doc;
                })
                .filter((document) => {
                  if (applicant.amlCheck && document.documentType === 'compliance_document') {
                    return;
                  }
                  return document;
                })
                .filter((ele) => ele)
                .filter((doc) => {
                  if (
                    !applicant.correspondenceAddressEdited &&
                    doc.documentType === 'correspondenceAddress'
                  ) {
                    return;
                  }
                  return doc;
                })
                .filter((ele) => ele)
                .map((doc) => {
                  if (
                    applicant.correspondenceAddressEdited &&
                    doc.documentType === 'correspondenceAddress'
                  ) {
                    return { ...doc, required: 'true' };
                  }
                  return doc;
                })
                .map((_doc) => {
                  if (
                    applicant?.dlclId &&
                    !cleardpIdField(applicant?.dlclId || '') &&
                    _doc.documentType === 'demat_information'
                  ) {
                    return { ..._doc, required: 'true' };
                  }
                  return _doc;
                })
                .map((doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
                  const { documentType, documentName, multipleFiles, required, options } = doc;
                  const { documents: existingDocuments = [] } = applicant || {};
                  const docsOfCurrentDocType = existingDocuments
                    .filter((doc) => doc.documentType === documentType)
                    .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
                  return {
                    documentType: documentType,
                    documentName: documentName,
                    documentsList: docsOfCurrentDocType.length
                      ? docsOfCurrentDocType.map((doc, ind) => {
                          const {
                            documentName = '',
                            documentType = '',
                            documentId = '',
                            isActive = true,
                            file = undefined,
                          } = doc;
                          return {
                            documentType,
                            documentName,
                            documentId,
                            isActive,
                            file,
                            options,
                            required,
                            uniqueKey: (applicant_index.toString() +
                              '-' +
                              index.toString() +
                              '-' +
                              ind.toString()) as string,
                          };
                        })
                      : [
                          {
                            documentType,
                            documentName,
                            required,
                            options,
                            uniqueKey: (applicant_index.toString() +
                              '-' +
                              index.toString() +
                              '-' +
                              '0') as string,
                            ...document_object,
                          },
                        ],
                    required,
                    multipleFiles,
                    options,
                  };
                })
            : [],
      };
    }),
    nominees: Nominees.map((nominee, nominee_index) => {
      return {
        nomineedocuments:
          typeof documentsData !== 'undefined'
            ? (isMinor(nominee.dateOfBirth || '')
                ? [
                    ...((documentsData as Document)[APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_DOC] ||
                      []),
                    ...((documentsData as Document)[
                      APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_GUARDIAN
                    ] || []),
                  ].map((doc) => {
                    if (doc.documentType === 'nomineeIdProof') {
                      return { ...doc, required: 'false' };
                    }
                    return doc;
                  })
                : (documentsData as Document)[APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_DOC] || []
              ).map((doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
                const { documentType, documentName, multipleFiles, required, options } = doc;
                const { nomineedocuments: existingDocuments = [] } = nominee || {};
                const docsOfCurrentDocType = existingDocuments
                  .filter((doc) => doc.documentType === documentType)
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
                return {
                  documentType: documentType,
                  documentName: documentName,
                  documentsList: docsOfCurrentDocType.length
                    ? docsOfCurrentDocType.map((doc, ind) => {
                        const {
                          documentName = '',
                          documentType = '',
                          documentId = '',
                          isActive = true,
                          file = undefined,
                        } = doc;
                        return {
                          documentType,
                          documentName,
                          documentId,
                          isActive,
                          file,
                          options,
                          required,
                          uniqueKey: (nominee_index.toString() +
                            '-' +
                            index.toString() +
                            '-' +
                            ind.toString()) as string,
                        };
                      })
                    : [
                        {
                          documentType,
                          documentName,
                          required,
                          options,
                          uniqueKey: (nominee_index.toString() +
                            '-' +
                            index.toString() +
                            '-' +
                            '0') as string,
                          ...document_object,
                        },
                      ],
                  required,
                  multipleFiles,
                  options,
                };
              })
            : [],
      };
    }),
  };
  try {
    await validateYupSchema(schemaData, documentDetailsSchema, true, schemaData);
  } catch (error) {
    throw `Document details`;
  }
};

export const isFormValidForSubmission = async (
  application: ApplicationProps | null,
  validateDoc = true,
  verifyPennydrop = true,
  nationalitiesMdmsMasters = {},
  brokerList: Broker[] = [],
  documentsResponse = {},
  role = ''
) => {
  const {
    applicants = [],
    nominees = [],
    doNotWishToNominate = false,
    banks = [],
    hasPOA = true,
    applicationType = '',
  } = application || {};

  try {
    await validateContributorDetails(applicants, role, nationalitiesMdmsMasters as nationaliyType);
    await validateKYCDetails(applicants, role, brokerList, application?.createdAt as string);
    await validateFATCA(applicants, role, nationalitiesMdmsMasters as nationaliyType);
    await validateNomineeDetails(nominees, doNotWishToNominate, applicants);
    await validateBankDetails(
      banks,
      verifyPennydrop,
      applicationType,
      applicants,
      application?.investor_bank_type || ''
    );
    validateDoc &&
      Object.keys(documentsResponse).length !== 0 &&
      (await validateDocuments(applicants, hasPOA, documentsResponse as Document, nominees, role));
  } catch (error) {
    if (error instanceof BankError) {
      throw (error as BankError).message;
    }
    throw `In ${error}, the required fields are not filled.`;
  }
};

export const pennyDropVerification =
  (body: Bank) =>
  async (dispatch: any): Promise<Bank> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/pennydropverification`,
        method: 'POST',
        types: [PENNY_DROP_VERIFICATION_SUCCESS, PENNY_DROP_VERIFICATION],
        body,
      },
    });
  };

export const getDocuments =
  () =>
  async (dispatch: any): Promise<Document> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/required_document_master.json',
        method: 'GET',
        types: [GET_Documents_SUCCESS, GET_Documents],
      },
    });
  };

export const getBrokers =
  () =>
  async (dispatch: any): Promise<BrokerList> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/registered_broker_list.json',
        method: 'GET',
        types: [GET_BROKERS_SUCCESS, GET_BROKERS],
      },
    });
  };

export const getUboTypes =
  () =>
  async (dispatch: any): Promise<uboTypes> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/uboTypeMaster.json',
        method: 'GET',
        types: [GET_Ubo_SUCCESS, GET_Ubo],
      },
    });
  };

export const FetchData =
  (body: FetchUBORequestBody) =>
  async (dispatch: any): Promise<ubo> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/kradetails`,
        method: 'POST',
        types: [UBO_LISTING_SUCCESS, UBO_LISTING],
        body,
      },
    });
  };

export const FatcaMdmsData =
  () =>
  async (dispatch: any): Promise<FatcaMdms> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/fatca_data.json',
        method: 'GET',
        types: [GET_Fatca_SUCCESS, GET_Fatca],
      },
    });
  };

export const validateNonIndividualContributorDetails = async (
  applicants: Partial<Applicant>[],
  brokerList: Broker[],
  createdDate: string
) => {
  const schemaData = {
    applicants: applicants.map((applicant) => {
      return {
        ...applicant,
        dlclId: applicant.dlclId ? applicant.dlclId : dlclIdFieldMandatory() ? '' : 'none',
        nameOfBroker: applicant.nameOfBroker || '',
      };
    }),
  };
  try {
    await validateYupSchema(
      schemaData,
      NonIndividualContributorValidationSchema(brokerList as Broker[], createdDate || ''),
      true,
      schemaData
    );
  } catch (error) {
    throw `Contributor details`;
  }
};
export const validateNonIndividualContactDetails = async (
  applicants: Partial<Applicant>[],
  countryDropdown: string[]
) => {
  const schemaData = {
    applicants: applicants.map((applicant) => {
      const correspondence = getAddressData('correspondence', applicant.addresses);
      const permanent = getAddressData('permanent', applicant.addresses);
      const defaultPayload = {
        contactperson: applicant.contactperson
          ? {
              ...applicant.contactperson,
              countryNameAndCode: applicant.contactperson.countryNameAndCode
                ? applicant.contactperson.countryNameAndCode
                : 'India: +91',
              country: applicant.contactperson.country
                ? applicant.contactperson.country.toUpperCase()
                : 'INDIA',
            }
          : contactPersonObject,
        address: {
          correspondence,
        },
      };
      const permanentAddressPayload = correspondence.permanentAddressSameAsCorresponding
        ? defaultPayload
        : {
            ...defaultPayload,
            address: {
              ...defaultPayload.address,
              permanent: {
                ...permanent,
                country: permanent.country ? permanent.country.toUpperCase() : 'INDIA',
              },
              correspondence: {
                ...correspondence,
                country: correspondence.country ? correspondence.country.toUpperCase() : 'INDIA',
              },
            },
          };
      return permanentAddressPayload;
    }),
    countryDropdown: countryDropdown,
  };
  try {
    await validateYupSchema(schemaData, nonIndividualContactDetailsSchema, true, schemaData);
  } catch (error) {
    throw `Contact details`;
  }
};

export const validateNonIndividualDocumentDetails = async (
  applicants: Partial<Applicant>[],
  documentsData: individuals_Poa_nonIndividuals_Documents[],
  banks: Partial<Bank>[]
) => {
  const schemaData = {
    applicants: applicants.map((applicant, applicant_index) => {
      return {
        documents: documentsData
          .filter((document) => {
            if (applicant.amlCheck && document.documentType === 'compliance_document') {
              return;
            }
            return document;
          })
          .filter((ele) => ele)
          // .filter((doc) => {
          //   if (
          //     !applicant.correspondenceAddressEdited &&
          //     doc.documentType === 'correspondenceAddress'
          //   ) {
          //     return;
          //   }
          //   return doc;
          // })
          // .filter((ele) => ele)
          // .map((doc) => {
          //   if (
          //     applicant.correspondenceAddressEdited &&
          //     doc.documentType === 'correspondenceAddress'
          //   ) {
          //     return { ...doc, required: 'true' };
          //   }
          //   return doc;
          // })
          .map((_doc) => {
            if (
              applicant?.dlclId &&
              !cleardpIdField(applicant?.dlclId || '') &&
              _doc.documentType === 'demat_information'
            ) {
              return { ..._doc, required: 'true' };
            }
            return _doc;
          })
          .map((doc, index) => {
            const { documentType, documentName, multipleFiles, required, options } = doc;
            const { documents: existingDocuments = [] } = applicant || {};
            const docsOfCurrentDocType = existingDocuments
              .filter((doc) => doc.documentType === documentType)
              .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
            return {
              documentType: documentType,
              documentName: documentName,
              documentsList: docsOfCurrentDocType.length
                ? docsOfCurrentDocType.map((doc, ind) => {
                    const {
                      documentName = '',
                      documentType = '',
                      documentId = '',
                      isActive = true,
                      file = undefined,
                    } = doc;
                    return {
                      documentType,
                      documentName,
                      documentId,
                      isActive,
                      file,
                      options,
                      required,
                      uniqueKey: (applicant_index.toString() +
                        '-' +
                        index.toString() +
                        '-' +
                        ind.toString()) as string,
                    };
                  })
                : [
                    {
                      documentType,
                      documentName,
                      required,
                      options,
                      uniqueKey: (applicant_index.toString() +
                        '-' +
                        index.toString() +
                        '-' +
                        '0') as string,
                      ...document_object,
                    },
                  ],
              required,
              multipleFiles,
              options,
            };
          }),
      };
    }),
    banks: banks,
  };
  try {
    await validateYupSchema(schemaData, nonIndividualDocumentDetailsSchema, true, schemaData);
  } catch (error) {
    throw `Document details`;
  }
};
const fieldsTocheckForAuthorisedSignatories = (investorType: string) => [
  {
    key: 'name',
    label: 'Name',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'pan',
    label: 'PAN',
    validateMulitple: true,
    regex: individualPanRegex,
    regexMsg: 'Only Individual PANs are allowed',
    required: isForeignEntityTypeInvestor(investorType) ? false : true,
  },
  { key: 'mobile', label: 'Mobile Number', validateMulitple: true, required: true },
  {
    key: 'email',
    label: 'Email ID',
    validateMulitple: true,
    regex: emailRegex,
    required: true,
  },
  {
    key: 'designation',
    label: 'Designation',
    validateMulitple: false,
    required: true,
  },
];

export const validateNonIndividualAuthorisedSignatories = async (
  groups: Groups[],
  investorType: string
) => {
  try {
    groups.map((group) => {
      const getActiveSignatories = group.groupsignatories.filter((item) => item.isActive);
      const getCanEsignCount = getActiveSignatories.filter((item) => item.canEsign);
      if (!getActiveSignatories.length) {
        throw new AuthorisedErrors('Please add Authorised Signatories');
      }
      if (!getCanEsignCount.length) {
        throw new AuthorisedErrors('Please Select a member for E-Sign in Authorised Signatories');
      }
      if (getCanEsignCount.length < group.threshold) {
        throw new AuthorisedErrors(
          'Authorised Signatories that can esign can not be less than the Total No. of required Authorised Signatories'
        );
      }
      getActiveSignatories.forEach((signatory, index) => {
        fieldsTocheckForAuthorisedSignatories(investorType).forEach((field) => {
          if (
            (!signatory[field.key as keyof GroupSignatories] as unknown as string) &&
            field.required
          ) {
            throw new AuthorisedErrors(`${field.label} is required for signatory ${index + 1}`);
          }
          if (signatory[field.key as keyof GroupSignatories] as unknown as string) {
            if (
              field.regex &&
              !field.regex.test(signatory[field.key as keyof GroupSignatories] as unknown as string)
            ) {
              throw new AuthorisedErrors(
                field.regexMsg
                  ? `${field.regexMsg} for signatory ${index + 1}`
                  : `Please enter valid ${field.label} for signatory ${index + 1}`
              );
            }
            if (field.validateMulitple) {
              const values = getActiveSignatories.filter(
                (vsignatory) =>
                  (vsignatory[field.key as keyof GroupSignatories] as unknown as string) ===
                  (signatory[field.key as keyof GroupSignatories] as unknown as string)
              );
              if (values.length > 1) {
                throw new AuthorisedErrors(
                  `There is already same ${field.label} for an Authorised Signatory associated with this application`
                );
              }
            }
          }
        });
      });
      return group;
    });
  } catch (error) {
    if (error instanceof AuthorisedErrors) {
      throw error;
    }
  }
};

const fieldsTocheckForUbos = [
  {
    key: 'panNumber',
    label: 'Taxpayer ID Number/PAN/Equivalent ID Number',
    validateMulitple: true,
    required: true,
  },
  {
    key: 'dob',
    label: 'Date of Birth',
    validateMulitple: false,
    required: true,
  },
  { key: 'name', label: 'Name of UBO', validateMulitple: false, required: true },
  {
    key: 'identificationType',
    label: 'Identification Type',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'percentageOfBeneficialInterest',
    label: 'Percentage of beneficial interest',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'countryOfTaxResidency',
    label: 'Country of Tax Residency',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'cpUboCode',
    label: 'CP/UBO Code',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'placeAndCountryOfBirth',
    label: 'Place & Country of Birth',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'occupation',
    label: 'Occupation',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'gender',
    label: 'Gender',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'nationality',
    label: 'Nationality',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'fatherName',
    label: 'Father Name',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'ckycNumber',
    label: 'CKYC Number',
    validateMulitple: true,
    required: false,
  },
  {
    key: 'address2',
    label: 'Address 1',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'address3',
    label: 'Address 2',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'pincode',
    label: 'Pincode',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'city',
    label: 'City',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'state',
    label: 'State',
    validateMulitple: false,
    required: true,
  },
  {
    key: 'country',
    label: 'Country',
    validateMulitple: false,
    required: true,
  },
];
const validateNonIndividualUbo = async (
  applicants: Partial<Applicant>[],
  ubo_declaration_type: string,
  ubo_declaration_value: string,
  ubos: ubo[],
  nationalityDropdown: string[],
  countryDropdown: string[]
) => {
  try {
    const applicantsCkycNumbers = applicants?.map((applicant) => applicant.ckycNo);
    if (!ubo_declaration_type) {
      throw new UboErrors('Please Select Declaration Type in Declaration of Ubo');
    }
    if (!ubo_declaration_value) {
      throw new UboErrors('Please Select Declaration value in Declaration of Ubo');
    }
    if (ubo_declaration_type === 'none' && !ubos.filter((_item) => _item.isActive).length) {
      throw new UboErrors('Please Add Ultimate Beneficiary Owners(UBO)');
    }
    if (ubos.filter((_item) => _item.isActive).length) {
      const activeUbos = ubos.filter((_item) => _item.isActive);
      activeUbos.map((ubo) => {
        if (ubo.ckycNumber && applicantsCkycNumbers.includes(ubo.ckycNumber)) {
          throw new UboErrors(
            `CKYC Number of ${ubo.name} should not be same as CKYC Number exist in Contributor Details`
          );
        }
        if (!nationalityDropdown.includes(ubo.nationality || '')) {
          throw new UboErrors(`Invalid Nationality for ${ubo.name} in Declaration of Ubo`);
        }
        if (!countryDropdown.includes(ubo.country || '')) {
          throw new UboErrors(`Invalid Country for ${ubo.name} in Declaration of Ubo`);
        }
        if (!countryDropdown.includes(ubo.countryOfTaxResidency || '')) {
          throw new UboErrors(
            `Invalid Country of Tax Residency for ${ubo.name} in Declaration of Ubo`
          );
        }
        if (!occupationDetailsMasters.includes(ubo.occupation || '')) {
          throw new UboErrors(`Invalid Occupation for ${ubo.name} in Declaration of Ubo`);
        }
      });
      percentageError(ubos, true);
    }
    ubos
      .filter((_item) => _item.isActive)
      .forEach((ubo, index) => {
        fieldsTocheckForUbos.forEach((field) => {
          if ((!ubo[field.key as keyof ubo] as unknown as string) && field.required) {
            throw new UboErrors(`${field.label} is required for ubo ${index + 1}`);
          }
          if (
            field.key === 'dob' &&
            (ubo[field.key as keyof ubo] as unknown as string) &&
            isMinor(field.key)
          ) {
            throw new UboErrors(`Age should be greater than 18 for ubo ${index + 1}`);
          }
          if (
            field.key === 'percentageOfBeneficialInterest' &&
            (ubo[field.key as keyof ubo] as unknown as number) <= 0
          ) {
            throw new UboErrors(
              `Percentage Of Beneficial Interest should be greater 0 for ubo ${index + 1}`
            );
          }
          if (
            field.key === 'percentageOfBeneficialInterest' &&
            (ubo[field.key as keyof ubo] as unknown as number) > 100
          ) {
            throw new UboErrors(
              `Percentage Of Beneficial Interest must not exceed 100% for ubo ${index + 1}`
            );
          }
          if (field.validateMulitple) {
            const values = ubos.filter(
              (_ubo) =>
                (_ubo[field.key as keyof ubo] as unknown as string) ===
                  (ubo[field.key as keyof ubo] as unknown as string) &&
                (ubo[field.key as keyof ubo] as unknown as string) &&
                (_ubo[field.key as keyof ubo] as unknown as string)
            );
            if (values.length > 1) {
              throw new UboErrors(
                `There is already same ${field.label} for an Ubos associated with this application`
              );
            }
          }
        });
      });
  } catch (e) {
    if (e instanceof UboErrors) {
      throw e;
    }
  }
};
export const nonIndividualFormValidForSubmission = async (
  application: ApplicationProps | null,
  documentsData: individuals_Poa_nonIndividuals_Documents[],
  mdmsCountriesList: mdmsCountriesList[],
  brokerList: Broker[],
  verifyPennydrop = true,
  role = ''
) => {
  const {
    applicants = [],
    banks = [],
    groups = [],
    ubo_declaration_type = '',
    ubo_declaration_value = '',
    ubos = [],
    applicationType = '',
  } = application || {};
  const nationalityDropdown = mdmsCountriesList.map((list) => list.nationality);
  const countryDropdown = mdmsCountriesList.map((list) => list.name);
  try {
    await validateNonIndividualContributorDetails(
      applicants,
      brokerList as Broker[],
      application?.createdAt as string
    );
    await validateNonIndividualContactDetails(applicants, countryDropdown);
    await validateBankDetails(
      banks,
      verifyPennydrop,
      applicationType,
      applicants,
      application?.investor_bank_type || ''
    );
    await validateNonIndividualDocumentDetails(applicants, documentsData, banks);
    await validateNonIndividualAuthorisedSignatories(groups, applicants[0]?.investorType || '');
    await validateNonIndividualUbo(
      applicants,
      ubo_declaration_type,
      ubo_declaration_value,
      ubos,
      nationalityDropdown,
      countryDropdown
    );
  } catch (error) {
    if (error instanceof UboErrors) {
      throw (error as UboErrors).message;
    }
    if (error instanceof AuthorisedErrors) {
      throw (error as AuthorisedErrors).message;
    }
    if (error instanceof BankError) {
      throw (error as BankError).message;
    }
    throw `In ${error}, the required fields are not filled.`;
  }
};
